.authorListings {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .title{
        margin-bottom: 0;
        padding-bottom: 0;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
    }

    & .listings{
        display: flex;
        flex-direction: column;
        & .viewMore{
            align-self: flex-end;
            padding: 5px
        }
    }
    & .listingsContainer {
        width: 100vw;
        display: flex;
        gap: 1rem;
        padding-inline: 1rem;
        overflow-x: scroll;
        align-items: center;
        margin-top: 0;
        @media only screen and (min-width:750px) {
            max-width: 60vw;
            gap: 2rem;
        };

        & .listing {
            min-width: 300px;
        }
    }
}

.rating{
    margin: 0;
    margin-left: 2px;
    display: inline;
    margin-bottom: 10px;
}